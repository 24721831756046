<template>
  <b-card-code title="Role List">
    <!-- search input -->
    <b-row>
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <b-button
          variant="primary"
          @click="$router.push({ name: 'admin-role-add'})"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        >
          <span class="text-nowrap">Add Role</span>
        </b-button>
        <div>
          <b-sidebar
            id="sidebar-right"
            bg-variant="white"
            sidebar-class="sidebar-lg"
            right
            backdrop
            shadow
          >
            <template #default="{ hide }" v-show="isShow">
              <!-- Header -->
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  content-sidebar-header
                  px-2
                  py-1
                "
              >
                <h5 class="mb-0">Permission</h5>
              </div>
              <validation-observer ref="simpleRules">
                <!-- Body -->
                <b-form class="p-2">
                  <b-form-group label="Name" label-for="name">
                    <b-form-input id="name" v-model="name" trim />
                  </b-form-group>
                  <!-- Form Actions -->
                  <div class="d-flex mt-2">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-2"
                      type="submit"
                      @click.prevent="validationForm"
                      @click="hide"
                    >
                      Send
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      @click="hide"
                      @click.prevent="closeSideBar"
                    >
                      Cancel
                    </b-button>
                  </div>
                </b-form>
              </validation-observer>
            </template>
          </b-sidebar>
        </div>
      </b-col>
      <b-col cols="12" md="6">
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block mr-1"
            placeholder="Search..."
          />
        </div>
      </b-col>
    </b-row>
    <br />
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      theme="my-theme"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'name'" class="text-nowrap">
          <span>{{ props.row.name }}</span>
        </span>
        <!-- Column: Actions --> 
        <div class="text-nowrap" v-if="props.column.field == 'actions'">
          <feather-icon
            icon="EditIcon"
            size="16"
            class="mx-1"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'" 
           @click="$router.push({ name: 'admin-role-edit', params: { roleId: props.row.id }})"
          />
        </div>
      </template> 
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BLink,
  BTooltip,
  BMedia,
  BBadge,
  BButton,
  VBToggle,
  BSidebar,
  BForm,
  BFormTextarea,
} from "bootstrap-vue";
import { ADMIN_ROLE_LIST } from "@core/services/api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
// import store from '@/store/index'
import store from "@/store";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import useJwt from "@/auth/jwt/useJwt";
import vSelect from "vue-select";
import { required, email } from "@validations";
import { checkRes } from "@core/services/helper";
// Use toast
import Mixin from "@core/services/mixin";

export default {
  mixins: [Mixin],
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BLink,
    BTooltip,
    BMedia,
    BBadge,
    BButton,
    BSidebar,
    BForm,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() { 
    return { 
      toggle: false,
      isShow: false,
      pageLength: 10,
      dir: false,
      required,
      columns: [
        { field: "name", label: "Name", sortable: true },
        { field: "actions", label: "Action" },
      ],
      rows: [], 
      isp_list: [],
      isp_id: "",
      searchTerm: "", 
      name: null,
      status: "",
      updateStatus: "",
      permissionId: "", 
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.listSettlementData();
  },
  methods: {
    listSettlementData() {
      this.$http
        .get(process.env.VUE_APP_BASEURL + ADMIN_ROLE_LIST, {
          params: {},
        })
        .then((res) => {
          this.rows = res.data.data;
        }).catch((error) => {
          if (error.response.status === 404) {
            userData.value = undefined;
          }
        });
    },
    closeSideBar() {
      this.permissionId = null;
      this.name = null;
    },
    permissionUpdateIdSet(id, name) {
      this.permissionId = id;
      this.name = name;
    }, 
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          useJwt
            .addOrUpdatePermission({
              permissionId: this.permissionId ?? null,
              name: this.name ?? null,
            })
            .then((response) => {
              console.log(response.status);
              this.listSettlementData();
              if (checkRes(response.status) && response.data.code) {
                this.isShow = false;
                this.name = null;
                this.$router
                  .push({ name: "admin-permission-list" })
                  .catch((success) => {
                    this.toastMessage(
                      "success",
                      "Permission Add Successfully",
                      response
                    );
                  });
              } else {
                this.$router
                  .push({ name: "admin-permission-list" })
                  .then(() => {
                    this.toastMessage("warning", "Something Wrong", response);
                  })
                  .catch((error) => {
                    this.toastMessage("danger", "Something Wrong", response);
                  });
              }
            });
        }
      });
    },
  },
  mounted() { 
  },
};
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>